import React from 'react'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LocalizedLink from '../i18n/localizedLink'
import Img from 'gatsby-image'
import styles from './blog.module.scss'

const BlogPage = ({
  pageContext: { limit, skip, numPages, currentPage, locale },
  data,
}) => {
  const hasPre = currentPage > 1 && numPages > 1
  const hasNext = currentPage < numPages && numPages > 1
  return (
    <Layout locale={locale}>
      <SEO
        title="List of Loquat blog articles"
        description="List of Loquat medical blog."
      />
      <main role="main" className={styles.main}>
        <section className={styles.hero}>
          <h1 className={styles.hero_heading}>
            <FormattedMessage id="blog" />
          </h1>
          <figure className={styles.hero_img}>
            <Img fluid={data.blog.childImageSharp.fluid} />
          </figure>
        </section>
        <section className={styles.blog}>
          {data.allContentfulBlog &&
            data.allContentfulBlog.edges.map(({ node }) => (
              <article key={node.articleId}>
                <LocalizedLink to={'/blog/' + node.articleId + '/'}>
                  <figure>
                    <Img fluid={node.eyecatch.fluid} />
                  </figure>
                  <h2>{node.title}</h2>
                  <time>{node.publishedAt}</time>
                </LocalizedLink>
              </article>
            ))}
        </section>
        <nav className={styles.pager}>
          <ul className={styles.pagination}>
            {hasPre && (
              <li className={styles.pre}>
                <LocalizedLink
                  to={
                    currentPage === 2
                      ? '/blog/'
                      : '/blog/' + (currentPage - 2) + '/'
                  }
                >
                  <span>&#60;</span>
                </LocalizedLink>
              </li>
            )}
            {Array.from({ length: numPages }).map((_, i) => {
              var link = i === 0 ? '' : `${i}/`
              return (
                <li key={i}>
                  <LocalizedLink
                    to={'/blog/' + link}
                    activeClassName={styles.active}
                  >
                    <span>{i + 1}</span>
                  </LocalizedLink>
                </li>
              )
            })}
            {hasNext && (
              <li className={styles.next}>
                <LocalizedLink to={'/blog/' + currentPage + '/'}>
                  <span>&#62;</span>
                </LocalizedLink>
              </li>
            )}
          </ul>
        </nav>
      </main>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query($skip: Int!, $limit: Int!, $locale: String!) {
    allContentfulBlog(
      sort: { fields: [publishedAt], order: DESC }
      filter: { node_locale: { eq: "ja-JP" }, culture: { eq: $locale } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          articleId
          title
          eyecatch {
            fluid(cropFocus: CENTER, maxWidth: 250, resizingBehavior: THUMB) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          publishedAt
          lastUpdatedAt
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    blog: file(relativePath: { eq: "head.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
